import * as apiGetLesson from '@/api/lessons/getLesson'
import * as apiGetLessons from '@/api/lessons/getLessons'

export default {
    namespaced: true,
    state: () => ({
        loading: false,
        lessonListItems: [],
        detailedLessons: {},
    }),
    mutations: {
        gotLessonListItems(state, lessons) {
            state.lessonListItems = lessons
        },

        gotLesson(state, lesson) {
            state.detailedLessons[lesson.id] = lesson
        },

        isLoading(state, loading) {
            state.loading = loading
        }
    },
    actions: {
        async getLessonListItems({ commit }) {
            commit('isLoading', true)

            console.log('Getting Lesson List Items');

            const result = await apiGetLessons.get();

            if (result) {
                commit('gotLessonListItems', result)
            }

            commit('isLoading', false)
        },

        async getLesson({ commit }, { lessonId }) {
            commit('isLoading', true)

            console.log('Getting Lesson:', lessonId);

            const result = await apiGetLesson.get(lessonId)

            if (result) {
                commit('gotLesson', result)
            }

            commit('isLoading', false)
        },



        async ensureLessonListItems({ commit, dispatch, getters }) {
            commit('isLoading', true)

            if (getters.lessonListItems.length === 0) {
                await dispatch('getLessonListItems')
            }

            commit('isLoading', false)
        },

        async ensureLesson({ commit, dispatch, getters }, { lessonId }) {
            commit('isLoading', true)

            if (!getters.lesson(lessonId)) {
                console.log('Lesson not cached. Getting from API.')
                await dispatch('getLesson', { lessonId: lessonId })
            }

            commit('isLoading', false)
        }
    },
    getters: {
        lessonListItems: (state) => state.lessonListItems,
        lesson: (state) => (lessonId) => {
            return state.detailedLessons[lessonId]
        }
    }
};