<template>
  <div class="card position-relative h-100">
    <img
      class="card-img-top-rounded"
      :src="imageOrDefault"
      alt="Campus image"
    />

    <div class="position-absolute w-100">
      <div class="flex justify-between m-3">
        <div>
          <span
            v-if="completed"
            class="badge status-badge bg-green-500 px-4 text-white"
          >
            <i class="fas fa-check text-lg" aria-hidden="true"></i>
          </span>
          <span v-else-if="meta" class="badge bg-primary p-2 text-dark">{{
            meta
          }}</span>
        </div>
        <div>
          <button class="btn btn-sm btn-secondary" @click="$emit('action')">
            <i
              class="fas"
              :class="icon"
              aria-hidden="true"
              style="font-size: 0.9rem"
            ></i>
            {{ actionText }}
          </button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <h5 class="card-title">{{ title }}</h5>
      <div>
        <p class="card-text">
          {{ truncatedDescription }}
        </p>
        <span
          v-if="description.split(' ').length > maxWords"
          class="cursor-pointer"
          @click="toggleShowMore"
        >
          {{ showMore ? "Show Less" : "Show More" }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: [
    "title",
    "description",
    "meta",
    "imageUrl",
    "actionText",
    "icon",
    "completed",
  ],
  data() {
    return {
      showMore: false,
      maxWords: 14,
    };
  },
  emits: ["action"],
  computed: {
    imageOrDefault() {
      return (
        this.imageUrl ??
        "https://media-exp1.licdn.com/dms/image/C561BAQEYonrtVbwOtA/company-background_10000/0/1519799746732?e=2159024400&v=beta&t=UWLOH5x-Ej0tRiAdObztvxnsi4t-2b5dkTbafuTGJ1g"
      );
    },
    truncatedDescription() {
      if (this.showMore) {
        return this.description;
      }
      const words = this.description.split(" ");
      return words.length > this.maxWords
        ? words.slice(0, this.maxWords).join(" ") + "..."
        : this.description;
    },
  },
  methods: {
    toggleShowMore() {
      this.showMore = !this.showMore;
    },
  },
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card-img-top-rounded {
  width: 100%;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.status-badge {
  border-radius: 20px;
}
.badge {
  white-space: pre-wrap;
  text-align: left;
}

/* .button-upper-right {
  position: absolute;
  top: 24px;
  right: 24px;
}

.meta-upper-left {
  position: absolute;
  top: 24px;
  left: 24px;
} */

.card {
  border-radius: 16px;
  border: none;
  box-shadow: 0 1px 2.2px rgb(0 0 0 / 2%), 0 2.3px 5.3px rgb(0 0 0 / 3%),
    0 4.4px 10px rgb(0 0 0 / 4%), 0 7.8px 17.9px rgb(0 0 0 / 4%),
    0 14.6px 33.4px rgb(0 0 0 / 5%), 0 35px 80px rgb(0 0 0 / 7%);
}

h2.page-title {
  font-weight: 800;
  color: #fff;
}
h5.page-title {
  color: #fff;
}

.card-img-overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card-title {
  overflow: hidden;
  text-overflow: ellipsis;
  color: #000;
}

.btn.round {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

h5.copy-to-clipboard:hover {
  cursor: pointer;
  color: cornflowerblue;
}

.row {
  display: flex;
}

.left {
  flex: 1;
}

.right {
  width: 350px;
}
</style>