import apiClient from '@/services/axios'

export async function get(courseId) {
  console.log('Getting course view model for course', courseId);
  var response = await apiClient.get(`CourseInstances/${courseId}`)
  if (response) {
    return response.data
  }

  throw new Error('Unable to get course.')
}
