<template>
  <div class="flex flex-col items-center gap-3">
    <ProgressSpinner
      style="width: 50px; height: 50px; stroke: #e6bd71 !important"
      strokeWidth="5"
      animationDuration="1s"
      aria-label="Custom ProgressSpinner"
    />
    <div class="text-gray-700 text-sm">{{ text }}</div>
  </div>
</template>

<script>
export default {
  name: "Loading",
  props: {
    text: {
      type: String,
      required: false,
      default: "Loading",
    },
  },
};
</script>

<style>
.p-progress-spinner-circle {
  stroke: #07332e !important;
}
</style>