<template>
  <div class="row mb-3">
    <label for="inputText" class="col-form-label">{{ label }}</label>
    <div class="col-sm-8">
      <select v-model="selected" class="form-control">
        <option disabled value="">{{ placeholder }}</option>
        <option v-for="(option, index) in options" :key="index" :value="option.value">{{ option.label }}</option>
      </select>
    </div>
  </div>
</template>
<script>
export default {
  name: "SelectList",
  props: ["placeholder", "label", "name", "value", "options"],
  data() {
    return {
      selected: this.value || ''
    };
  },
  methods: {
    onChange($event) {
      const value = $event.target.value
    
      this.$emit("onChange", value);
    },
  },
};
</script>

<style lang="scss">
</style>