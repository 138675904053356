<template>
  <div style="display: flex;flex: 1;width: 100%;">
    <slot name="sidebar"></slot>

    <div style="display: flex;flex-direction: column;flex: 1;">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "LeftMenuLayout",
  components: {
  },
  props: {},
  data: function () {
    return {
    }
  },
  methods: {
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
