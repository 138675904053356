import { msalInstance } from '@/libs/vue-msal-browser'
import * as jwt_decode from 'jwt-decode'
import { reactive, computed } from 'vue'

import { b2cPolicies } from './msal/policies'

const state = reactive({
  providedToken: null,
  loading: true,
  isAuthenticated: false,
  user: {}
})

export const authPlugin = {
  providedToken: computed(() => state.providedToken),
  isAuthenticated: computed(() => state.isAuthenticated),
  loading: computed(() => state.loading),
  user: computed(() => state.user),
  getToken,
  logout,
  /*getIdTokenClaims,
  getTokenSilently,
  getTokenWithPopup,
  handleRedirectCallback,
  loginWithRedirect,
  loginWithPopup,
  logout,*/
}

function getUser(accessToken) {
  var decoded = jwt_decode(accessToken);

  console.log(decoded)

  return {
    name: decoded.name,
    firstName: decoded.given_name,
    lastName: decoded.family_name,
    role: decoded.extension_UserRole,
    type: decoded.extension_UserType,
    picture: 'https://rekomlifestorageprod.blob.core.windows.net/user-uploads/avatars/' + decoded.oid + '.jpg'
  }
}

async function getToken() {
  let existingTokenResponse = localStorage.getItem('rekom.campusonline.client.token')
  let newTokenResponse;

  if (state.providedToken) {
    state.user = getUser(state.providedToken)
    state.isAuthenticated = true
    state.loading = false
    return state.providedToken
  }

  try {
    if (existingTokenResponse && existingTokenResponse !== "null") {
      let existingToken = JSON.parse(existingTokenResponse)
      newTokenResponse = await msalInstance.acquireTokenSilent({ account: existingToken.account, scopes: msalInstance.config.auth.scopes });
    } else {
      newTokenResponse = await msalInstance.handleRedirectPromise();
      localStorage.setItem('rekom.campusonline.client.token', JSON.stringify(newTokenResponse))
    }
  } catch (error) {
    console.log('ERROR:', error)

    if (error.errorMessage.indexOf("AADB2C90077") > -1) {
      console.log('ERROR - AADB2C90077')

      const loginRequest = { scopes: msalInstance.config.auth.scopes };
      await msalInstance.loginRedirect(loginRequest);

      return null
    }
    if (error.errorMessage.indexOf("AADB2C90118") > -1) {
      console.log("Is resetting password")
      localStorage.setItem('rekom.life.isResettingPassword', true);
      await msalInstance.loginRedirect(b2cPolicies.authorities.resetPassword);
      return null
    } else {
      localStorage.setItem('rekom.life.isResettingPassword', false);
      console.log("Has just reset password")
      localStorage.clear();
      return null;
    }
  }

  if (!newTokenResponse) {
    return null
  }

  if (newTokenResponse) {
    state.user = getUser(newTokenResponse.accessToken)

    state.isAuthenticated = true
    state.loading = false

    return newTokenResponse.accessToken
  }
}

export const setupAuth = async (callbackRedirect, providedToken) => {
  state.providedToken = providedToken
  let token = await getToken()

  if (!token) {
    const loginRequest = { scopes: msalInstance.config.auth.scopes };
    await msalInstance.loginRedirect(loginRequest);

    return null
  }

  callbackRedirect()

  return {
    install: (app) => {
      app.config.globalProperties.$auth = authPlugin
    },
  }

  /*
  let existingTokenResponse = localStorage.getItem('rekom.campusonline.client.token')
  let newTokenResponse;

  if (existingTokenResponse && existingTokenResponse !== "null") {
    let existingToken = JSON.parse(existingTokenResponse)
    newTokenResponse = await msalInstance.acquireTokenSilent({ account: existingToken.account, scopes: msalInstance.config.auth.scopes });
  } else {
    newTokenResponse = await msalInstance.handleRedirectPromise();
  }

  if (!newTokenResponse) {
    const loginRequest = { scopes: msalInstance.config.auth.scopes };
    await msalInstance.loginRedirect(loginRequest);

    return false;
  } else if (newTokenResponse) { // There is an existing token, we authentify the user
    localStorage.setItem('rekom.campusonline.client.token', JSON.stringify(newTokenResponse))

    // We add the access token as an authorization header for our Axios requests to our API
    //this._vm.axios.defaults.headers.common['Authorization'] = "Bearer " + newTokenResponse.accessToken;

    return true;
  }*/
}

/*
let client
const state = reactive({
  loading: true,
  isAuthenticated: false,
  user: {},
  popupOpen: false,
  error: null,
})

function getUser() {
    var accessToken = localStorage.getItem('rekom.campusonline.client.accessToken');
    var decoded = jwt_decode(accessToken);

    return {
        name: client.account.name,
        firstName: client.account.idTokenClaims.given_name,
        lastName: client.account.idTokenClaims.family_name,
        role: decoded.extension_UserRole,
        type: decoded.extension_UserType,
        picture: 'https://rekomlifestorageprod.blob.core.windows.net/user-uploads/avatars/' + client.account.accountIdentifier + '.jpg'
    }
}

async function loginWithPopup() {
    state.popupOpen = true

    try {
        const response = await client.loginPopup(loginRequest)

        if (response.tokenType === 'id_token') {
            this.acquireTokenSilent();
        }
    } catch (e) {
        console.error(e)
    } finally {
        state.popupOpen = false
    }

    state.user = getUser()
    state.isAuthenticated = true
}

async function handleRedirectCallback(error, response) {
  state.loading = true

  if (error) {
    console.log(error);

    // Check for forgot password error
    // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
    if (error.errorMessage.indexOf("AADB2C90118") > -1) {
        try {
            localStorage.setItem('rekom.life.isResettingPassword', true);

            client.loginRedirect(b2cPolicies.authorities.resetPassword); // Password reset policy/authority
        } catch (err) {
            localStorage.setItem('rekom.life.isResettingPassword', false);

            console.log(err);
        }
    } else if (error.errorMessage.indexOf("AADB2C90091") > -1) {
        localStorage.setItem('rekom.life.isResettingPassword', false);
    } else {
        console.log(response);
        alert(error.errorMessage);
    }
} else {
    // We need to reject id tokens that were not issued with the default sign-in policy.
    if (response.tokenType === "id_token" && response.idToken.claims['tfp'] === b2cPolicies.names.resetPassword) {
        localStorage.setItem('rekom.life.isResettingPassword', false);
        this.logout();
        console.log("Password has been reset successfully. \nPlease sign-in with your new password.");
    } else if (response.tokenType === "id_token" && response.idToken.claims['tfp'] === b2cPolicies.names.signIn) {
        //alert('Got id_token from sign in policy');
        //ctx.getTokenRedirect();
    } else if (response.tokenType === "access_token") {

        state.user = getUser()
        state.isAuthenticated = true
        state.loading = false

        this.onAuthenticated(response);
    } else {
        console.log("Token type is: " + response.tokenType);
    }
  }
}

async function loginWithRedirect(redirectUrl) {
  localStorage.setItem('rekom.life.redirectUrl', redirectUrl);

  const isResettingPassword = localStorage.getItem('rekom.life.isResettingPassword');
  if (isResettingPassword === 'true') {
      localStorage.setItem('rekom.life.isResettingPassword', false);
      return;
  }

  // if the user is already logged in you can acquire a token
  try {
    if (client.getAccount()) {
        const response = await getTokenSilently();
        return response
    } else {
        return client.loginRedirect(loginRequest);
    }
  } catch (error) {
    if (error.errorMessage.indexOf("AADB2C90077") > -1) {
      return await client.loginRedirect(loginRequest);
    }
  }
}

function getIdTokenClaims() {
    return client.account.idTokenClaims
  //return client.getIdTokenClaims(o)
}

function getTokenSilently() {
  return client.acquireTokenSilent(tokenRequest)
}

function getTokenWithPopup(o) {
  return client.getTokenWithPopup(o)
}
*/
function logout() {
  // Removes all sessions, need to call AAD endpoint to do full logout
  msalInstance.logout();
  localStorage.clear();

  //return client.logout(o)
}
/*

export const authPlugin = {
  isAuthenticated: computed(() => state.isAuthenticated),
  loading: computed(() => state.loading),
  user: computed(() => state.user),
  getIdTokenClaims,
  getTokenSilently,
  getTokenWithPopup,
  handleRedirectCallback,
  loginWithRedirect,
  loginWithPopup,
  logout,
}

export const setupAuth = async (callbackRedirect) => {

  client = new Msal.UserAgentApplication(msalConfig);
  client.handleRedirectCallback((error, response) => callbackRedirect(error, response));

  if (location.pathname !== '/auth-redirect') {

    const result = await loginWithRedirect(location.pathname + location.search);
    if (result) {
      localStorage.setItem('rekom.campusonline.client.accessToken', result.accessToken);
      state.user = getUser()
      state.isAuthenticated = true
      state.loading = false
    }
  } else {
    //alert('Pathname = auth-redirect.html')
    state.loading = false
  }

  return {
    install: (app) => {
      app.config.globalProperties.$auth = authPlugin
    },
  }
}*/