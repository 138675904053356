<template>
  <div style="display: flex; flex: 1; flex-direction: column">
    <NavBar></NavBar>
    <!--MainTopBar></MainTopBar>-->
    <router-view :key="$route.fullPath" />

    <!--
    <LeftMenuLayout>
      <template v-slot:sidebar>
        <MainSidebar></MainSidebar>
      </template>
      <template v-slot:content>
        <router-view :key="$route.fullPath" />
      </template>
    </LeftMenuLayout>
    -->
  </div>
</template>

<script>
//import MainTopBar from './sidebars/MainTopBar';

export default {
  name: "MainLayout",
  components: {
    //MainTopBar
  },
  props: {},
  /*
  watch: {
    '$route' (to, from) {
      console.log('Route changed from ' + from.path + ' to ' + to.path);
      console.log(to) 
    }
  },
  */
  data: function () {
    return {};
  },
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
