export default function evaluateVideoEventSequence(sequence, sourceNode, context) {
    const sequenceData = JSON.parse(sequence.json)
    var condition = new Function('context', 'node', 'return context[node.id + \'_state\'] === \'' + sequenceData.event + '\'');

    try {
        return condition(context, sourceNode)
    }
    catch {
        console.log('Warning: Could not evaluate VideoEndedSequence condition')

        return false
    }
}