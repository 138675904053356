export default function evaluateEqualValueSequence(sequence, sourceNode, context, isPassFail) {
    const sequenceData = JSON.parse(sequence.json)
    const strValue = sequenceData.value
    const strMultipleValues = sequenceData.multipleValues
    const strOrderedValues = sequenceData.orderedValues

    let condition = null
    const multipleValues = strMultipleValues === "${true}"
    if (multipleValues === false) {
        if (isPassFail) {
            condition = new Function('context', 'node', 'return context[node.id] !== undefined && context[node.id] !== null');
        } else {
            condition = new Function('context', 'node', 'return context[node.id] === \'' + strValue + '\'');
        }
    } else {
        const orderedValues = strOrderedValues === "${true}"

        const values = strValue.split(',');
        let joinedValues = values.sort().join(',')
        if (isPassFail) {
            condition = new Function('context', 'node', 'return context[node.id] !== undefined && context[node.id] !== null');
        } else {
            condition = new Function('context', 'node', 'return JSON.parse(context[node.id]).sort().join(\',\') === \'' + joinedValues + '\'');

            if (orderedValues === true) {
                joinedValues = sequenceData.value
                condition = new Function('context', 'node', 'return JSON.parse(context[node.id]).join(\',\') === \'' + joinedValues + '\'');
            }
        }
    }

    try {
        // console.log('Evaluating EqualValueSequence condition:', context, sourceNode, condition(context, sourceNode)());
        return condition(context, sourceNode)
        // return true;
    }
    catch {
        //console.log('Warning: Could not evaluate EqualValueSequence condition')

        return false
    }
}
