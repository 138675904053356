<template>
  <div class="mb-3">
    <label class="col-form-label">{{ element.title }}</label>
    <label>{{ element.description }}</label>
    <div class="col-sm-8">
      <draggable 
        v-model="items" 
        group="people" 
        @start="drag=true" 
        @end="drag=false" 
        item-key="id">
        <template #item="{element}">
          <div class="mb-1" style="border: 1px solid darkgray;padding: 8px 12px;">{{element}}</div>
        </template>
      </draggable>

      <button v-if="!buttonClicked" @click="onNextButton" type="button" class="btn btn-dark px-3 mt-2">{{nextButtonTitle}}</button>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
//import { useStore } from 'vuex'

import draggable from "vuedraggable";

export default {
  name: "SortableList",
  components: {
    draggable
  },
  props: [
    'id',
    'namespace',
    'json',
    'outgoing'
  ],
  setup(props/*, { emit }*/) {
    //const store = useStore()
    const buttonClicked = ref(false)

    const value = ref('')
    const element = JSON.parse( props.json )

    const nextButtonTitle = element.nextButtonTitle ?? 'Next'

    // Parse items
    const itemList = element.value;
    const itemNames = itemList.split(',');
    const parsedItems = []
    itemNames.forEach((itemName) => {
      parsedItems.push(itemName.trim())
    })
    const items = ref(parsedItems)
    const originalItems = ref(JSON.parse(JSON.stringify(parsedItems)))

    const drag = ref(false)

    onMounted(() => {
      //console.log('Mounted sortable list')
    })

    return {
      value,
      element,

      nextButtonTitle,

      drag,
      items,
      originalItems,

      buttonClicked
    };
  },

  methods: {
    onNextButton() {
      console.log('Values:', this.items)

      this.$store.dispatch('forms/cacheField', { 
        path: this.namespace + '.' + this.id, 
        value: this.items
      });

      this.$store.dispatch('forms/cacheField', { 
        path: this.namespace + '.' + this.id + '_button', 
        value: 'ok'
      });

      this.buttonClicked = true
    },

    refreshValue() {
      const loadedValue = this.$store.getters['forms/get'](this.namespace + '.' + this.id)
      if (loadedValue) {
        this.items = JSON.parse(loadedValue)
      } else {
        this.items = this.originalItems
      }

      const loadedButtonValue = this.$store.getters['forms/get'](this.namespace + '.' + this.id + '_button')
      if (loadedButtonValue === 'ok') {
        this.buttonClicked = true
      } else {
        this.buttonClicked = false
      }
    }
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === 'forms/fieldCached') {
        this.refreshValue()
      }
    });
  },
  mounted() {
    this.refreshValue()
  },
  beforeUnmount() {
    this.unsubscribe();
  },
};
</script>

<style scoped>
.mb-1.sortable-chosen {
  background-color: gainsboro;
  font-weight: 600;
}
</style>

<style lang="scss">
</style>
