import apiClient from '@/services/axios'

export async function put(courseId, lessonInstanceId) {
  var response = await apiClient.put(`/courseInstances/${courseId}/${lessonInstanceId}/complete`)
  if (response) {
    return response.data
  }

  throw new Error('Unable to complete Lesson.')
}
