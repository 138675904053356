import * as apiSubmitFeedback from '@/api/feedback/submitFeedback'



export default {
    namespaced: true,
    state: () => ({

    }),
    mutations: {

    },
    actions: {
        async submitFeedback({ commit },dto ) {
            commit;
            
            const result = await apiSubmitFeedback.post(dto)
            return result
        },

    },
    getters: {

    }
};