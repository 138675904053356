import apiClient from '@/services/axios'

export async function post(payload) {
  var response = await apiClient.post('/feedback/', payload)
  if (response) {
    console.log("Submitted feedback. Response", response.data);
    return response.data
  }

  throw new Error('Unable to create feedback ticket.')
}