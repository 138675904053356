<template>
  <div class="row mb-3">
    <label for="inputText" class="col-form-label">{{ element.title }}</label>
    <label>{{ element.description }}</label>
    <div class="col-sm-8">
      <input v-model="value" type="text" class="form-control" id="inputText" :placeholder="element.placeholder">
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  name: "NumberInput",
  props: [
    "id",
    "json",
    "outgoing"
  ],
  data() {
    const value = ref(0)
    const element = JSON.parse( this.json )

    return {
      value,
      element
    };
  }
};
</script>

<style lang="scss">
</style>
