// Sequences
import evaluateButtonOptionSequence from './sequences/buttonOptionSequence'
import evaluateDefaultSequence from './sequences/defaultSequence'
import evaluateRadioListOptionSequence from './sequences/radioListOptionSequence'
import evaluateEqualValueSequence from './sequences/equalValueSequence'
import evaluateVideoEventSequence from './sequences/videoEventSequence'

export default function evaluateSequence(sequence, sourceNode, context, isPassFail) {
    //console.log('Sequence type:', sequence.type)
    // console.log('Evaluating sequence:', sequence, isPassFail);

    switch (sequence.type) {
        case 'DefaultSequence':
            return evaluateDefaultSequence(sequence, sourceNode, context)

        case 'EqualValueSequence':
            return evaluateEqualValueSequence(sequence, sourceNode, context, isPassFail)

        case 'RadioListOptionSequence':
            return evaluateRadioListOptionSequence(sequence, sourceNode, context, isPassFail)

        case 'ButtonOptionSequence':
            return evaluateButtonOptionSequence(sequence, sourceNode, context)

        case 'VideoEventSequence':
            return evaluateVideoEventSequence(sequence, sourceNode, context)
    }

    return false
}