<template>
  <div>
    <!--<div v-for="(element) in visibleFormElements" :key="element.id">-->
    <div v-if="visibleFormElements != null && visibleFormElements.length > 0">
      <component
        :key="visibleFormElements[visibleFormElements.length - 1].id"
        v-if="visibleFormElements[visibleFormElements.length - 1].id"
        :is="
          visibleFormElements[visibleFormElements.length - 1].type ===
            'EndEvent' && isPassFail
            ? 'LessonSummary'
            : visibleFormElements[visibleFormElements.length - 1].type
        "
        :lessonInstanceId="props.lessonInstanceId"
        :namespace="namespace"
        v-bind="visibleFormElements[visibleFormElements.length - 1]"
      >
        <!--@onChange="onFormUpdated(element)"-->
        <!--         :is="true ? 'LessonSummary' : 'LessonSummary'" -->
      </component>
    </div>
  </div>
</template>

<script>
//import _ from "lodash";

import { useStore } from "vuex";

import { ServiceBus } from "@/leapform/LeapFormServiceBus";

import { ref } from "vue";

import { useToast } from "vue-toastification";

// Events
// import EndEvent from "./events/EndEvent";
import EndEvent from "./events/EndEvent";
import LessonSummary from "./events/LessonSummary";

// Elements
import PopUp from "./elements/PopUp";
import FormRepeater from "./elements/FormRepeater";

// Fields
import CurrencyInput from "./fields/CurrencyInput";

import FormInstance from "./fields/FormInstance";
import NumberInput from "./fields/NumberInput";
import RadioList from "./fields/RadioList";
import SelectList from "./fields/SelectList";
import MultipleChoice from "./fields/MultipleChoice";
import SwitchInput from "./fields/SwitchInput";
import TextInput from "./fields/TextInput";
import Video from "./fields/Video";
import SortableList from "./fields/SortableList";
import HelpMaterial from "./fields/HelpMaterial";

import evaluateSequence from "./sequenceEvaluator";

export default {
  inject: ["onLessonEndedRouteName"],
  name: "FormGenerator",
  components: {
    // Events
    EndEvent,

    // Elements
    PopUp,
    FormRepeater,

    // Fields
    CurrencyInput,

    FormInstance,
    NumberInput,
    RadioList,
    HelpMaterial,
    SelectList,
    MultipleChoice,
    SwitchInput,
    TextInput,

    Video,
    SortableList,
    LessonSummary,
  },
  props: [
    "id",
    "sessionId",
    "namespace",
    "elements",
    "modelValue",
    "lessonInstanceId",
    "isPassFail",
  ],
  setup(props) {
    const store = useStore();
    const toast = useToast();

    const formElements = ref(props.elements);
    const visibleFormElements = ref([]);

    const nodesToReset = ref([]);

    function getNodeById(nodeId) {
      const node = formElements.value.find((node) => node.id === nodeId);
      if (node) {
        return node;
      }

      console.log("Could not find node. Node ID = " + nodeId);

      return null;
    }

    let nodes = {};
    let flows = {};

    function resetFlows() {
      for (const flowId in flows) {
        const flow = flows[flowId];

        flow.visible = false;
      }
    }

    /*
    function evaluateFlows() {
      const context = store.getters['forms/get'](props.namespace)

      for (const flowId in flows) {
        const flow = flows[flowId]

        flow.visible = evaluateFlow(flow, context)
      }
    }
    */

    function evaluateFlows() {
      const context = store.getters["forms/get"](props.namespace);

      // Loop through each node
      for (const nodeId in nodes) {
        const node = nodes[nodeId];

        // Evaluate non-default flows first
        let nonDefaultVisible = false;
        node.outgoing.forEach(function (outgoingFlow) {
          if (outgoingFlow.type !== "DefaultSequence") {
            const flow = flows[outgoingFlow.flowId];
            const evaluteFlowResult = evaluateFlow(flow, context);
            // console.log(
            //   "Evaluated flow result: " +
            //     evaluteFlowResult +
            //     " for flow: " +
            //     flow.id +
            //     " from node: " +
            //     node.id +
            //     " to node: " +
            //     flow.to +
            //     "",
            //   context
            // );

            flow.visible = evaluteFlowResult;

            if (flow.visible) {
              nonDefaultVisible = true;
            }
          }
        });

        // If no non-default flow is enabled, try to enable any default flow
        if (!nonDefaultVisible) {
          node.outgoing.forEach(function (outgoingFlow) {
            if (outgoingFlow.type === "DefaultSequence") {
              const flow = flows[outgoingFlow.flowId];
              const evaluteFlowResult = evaluateFlow(flow, context);
              flow.visible = evaluteFlowResult;
            }
          });
        }
      }
    }

    function evaluateFlow(flow, context) {
      const sourceNode = getNodeById(flow.from);
      if (sourceNode === null) {
        return false;
      }

      const targetNode = getNodeById(flow.to);
      if (targetNode === null) {
        return false;
      }

      return evaluateSequence(
        flow.sequence,
        sourceNode,
        context,
        props.isPassFail
      );
    }

    function showFirstNode() {
      var firstNodeId = formElements.value[0].id;

      nodes[firstNodeId].isFirst = true;
      nodes[firstNodeId].visible = true;

      return nodes[firstNodeId];
    }

    function updateNodesFromFlowState() {
      for (const nodeId in nodes) {
        const node = nodes[nodeId];

        if (!node.isFirst) {
          var anyVisible = node.incoming.some(function (sequence) {
            const fromNode = nodes[sequence.nodeId];

            return fromNode.visible && flows[sequence.flowId].visible;
          });

          node.visible = anyVisible;
        }
      }
    }

    function removeNodesFrom(node) {
      console.log("Removing node:", node);
      nodesToReset.value.push({
        id: node.id,
        path: props.namespace + "." + node.id,
      });

      node.outgoing.forEach((sequence) => {
        const flow = flows[sequence.id];

        if (flow.visible === true) {
          console.log("Flow:", flow);

          const nextNode = nodes[flow.to];
          removeNode(nextNode);
        }
      });
    }

    function removeNode(node) {
      const removedNodes = nodesToReset.value.filter(
        (element) => element.id === node.id
      );
      if (removedNodes.length > 0) {
        console.log("Node already flagged for removing:", node);
        return;
      }

      console.log("Removing node:", node);
      nodesToReset.value.push({
        id: node.id,
        path: props.namespace + "." + node.id,
      });

      node.outgoing.forEach((sequence) => {
        const flow = flows[sequence.flowId];

        if (flow.visible === true) {
          console.log("Flow:", flow);

          const nextNode = nodes[flow.to];
          removeNode(nextNode);
        }
      });
    }

    function updateNodeVisibility(node) {
      if (node.visible === false) {
        return;
      }

      // Add visible node
      const activeElement = formElements.value.filter(
        (element) => element.id === node.id
      )[0];

      const existingElements = visibleFormElements.value.filter(
        (element) => element.id === node.id
      );
      if (existingElements.length > 0) {
        console.log("Found existing element:", existingElements[0]);

        removeNodesFrom(existingElements[0]);
        return;
      }

      visibleFormElements.value.push(activeElement);

      node.outgoing.forEach((sequence) => {
        const flow = flows[sequence.flowId];

        if (flow.visible === true) {
          const nextNode = nodes[flow.to];
          updateNodeVisibility(nextNode);
        }
      });
    }

    function refreshNodeVisibility() {
      resetFlows();
      evaluateFlows();
      let firstNode = showFirstNode();
      updateNodesFromFlowState();

      visibleFormElements.value = [];
      nodesToReset.value = [];

      updateNodeVisibility(firstNode);

      if (nodesToReset.value.length > 0) {
        console.log("Nodes to reset:", nodesToReset.value);

        store.dispatch("forms/resetFields", {
          nodes: nodesToReset.value,
        });

        refreshNodeVisibility();
      }
    }

    function restartVisibilityEvaluation() {
      nodes = {};

      formElements.value.forEach((node) => {
        // Reset all node evaluations
        nodes[node.id] = {
          id: node.id,
          visible: false,
          incoming: [],
          outgoing: [],
        };

        // Reset all flow evaluations
        node.outgoing.forEach((sequence) => {
          nodes[node.id].outgoing.push({
            flowId: sequence.id,
            from: node.id,
            to: sequence.targetRef,
            type: sequence.type,
          });

          if (!flows[sequence.id]) {
            flows[sequence.id] = {
              id: sequence.id,
              from: node.id,
              to: sequence.targetRef,

              sequence: sequence,

              visible: false,
            };
          }
        });
      });

      // Update incoming sequences
      formElements.value.forEach((node) => {
        node.outgoing.forEach((sequence) => {
          let evalNode = nodes[sequence.targetRef];

          if (evalNode) {
            evalNode.incoming.push({
              nodeId: node.id,
              flowId: sequence.id,
            });
          }
        });
      });
    }

    restartVisibilityEvaluation();
    refreshNodeVisibility();

    ServiceBus.instance().on("form:" + props.sessionId, (event) => {
      if (event.type !== "field-updated") {
        return;
      }

      //console.log(event)
      console.log("Field updated:", props.sessionId, event);
      console.log(flows);

      refreshNodeVisibility();
    });

    return {
      toast,
      formElements,
      props,
      visibleFormElements,
    };
  },
  methods: {
    /*
    onFormUpdated(node) {
      console.log('Updated:', node.id)

      const nodeContext = this.$store.getters['forms/get'](this.namespace + '.' + node.id)

      ServiceBus.instance().emit('form:' + this.sessionId, { event: 'updated' })
    },
    */
  },
};
</script>

<style lang="scss">
</style>
