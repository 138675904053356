import { createWebHistory, createRouter } from 'vue-router'

import { routeGuard } from '@/auth'

import MainLayout from '@/layouts/MainLayout'


import MyLearning from '@/pages/my-learning/MyLearning.vue'
import Certificates from '@/pages/certificates/Certificates.vue'

import CoursePage from '@/pages/courses/CoursePage.vue'

import LessonLayout from '@/pages/lessons/preview-lesson/LessonLayout.vue'
import LoadingLessonPage from '@/pages/lessons/preview-lesson/LoadingLessonPage.vue'
import DefaultLessonPage from '@/pages/lessons/preview-lesson/DefaultLessonPage.vue'

import NoAccount from '@/pages/common/NoAccount.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: MainLayout,
    beforeEnter: routeGuard,

    children: [
      {
        path: '',
        redirect: () => {
          return '/my-learning'
        }
      },
      {
        path: 'auth-redirect',
        redirect: () => {
          return '/my-learning'
        }
      },


      {
        path: 'my-learning',
        name: 'my-learning',
        component: MyLearning,
        beforeEnter: routeGuard
      },

      {
        path: 'certificates',
        name: 'certificates',
        component: Certificates,
        beforeEnter: routeGuard
      },

      {
        path: '/course/:courseId',
        name: 'course',
        component: CoursePage,
        props: route => ({
          coureId: route.params.courseId
        }),
        beforeEnter: routeGuard,
      },

      {
        path: '/my-campus/preview/:courseId/:lessonId',
        name: 'preview-lesson',
        component: LessonLayout,
        props: route => ({
          courseId: route.params.courseId,
          lessonId: route.params.lessonId
        }),
        beforeEnter: routeGuard,

        children: [
          {
            path: '',
            redirect: to => {
              const { params } = to
              return '/my-campus/preview/' + params.lessonId + '/loading'
            }
          },
          {
            path: 'loading',
            name: 'load-preview-lesson-page',
            component: LoadingLessonPage,
            props: route => ({ courseId: route.params.courseId, lessonId: route.params.lessonId })
          },
          {
            path: ':pageKey',
            name: 'preview-lesson-page',
            component: DefaultLessonPage,
            props: route => ({ courseId: route.params.courseId, lessonId: route.params.lessonId, pageKey: route.params.pageKey })
          }
        ]
      }
    ]
  },

  {
    path: '/no-account',
    name: 'no-account',
    component: NoAccount,
    beforeEnter: routeGuard
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;