import * as apiEnrollCourse from '@/api/courseInstances/enrollCourse'
import * as apiGetCourseViewModel from '@/api/courseInstances/getCourseViewModel'

export default {
    namespaced: true,
    state: () => ({

    }),
    mutations: {

    },
    actions: {
        async enrollCourse(_, courseId) {
            return await apiEnrollCourse.post(courseId)
        },
        async getCourseViewModel(_, courseId) {
            return await apiGetCourseViewModel.get(courseId)
        }
    },
    getters: {

    }
};