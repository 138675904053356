import { extractExpression } from './expressionExtractor'
import { evaluateExpression } from './expressionEvaluator'

export function evaluateFieldValue (field, context) {
  const expressions = extractExpression(field)

  if (expressions.length === 0) {
    return field // Field is not an expression. Return field as value.
  }

  let evaluatedField = field.slice(0)

  expressions.forEach(function (expression) {
    const expressionValue = evaluateExpression(expression[1], context)
    evaluatedField = evaluatedField.replace(expression[0], expressionValue)
  })

  let result = null

  try {
    result = evaluateExpression(evaluatedField, context)
  } catch (e) {
    console.log('Warning: Evaluated Field is probably not an expression. Returning by value.')
    result = evaluatedField
  }

  return result
}
