import * as apiGetCertificates from '@/api/certificates/getCertificates'

export default {
    namespaced: true,
    state: () => ({
        loading: false,
        certificates: [],
    }),
    mutations: {
        setCertificates(state, certificates) {
            state.certificates = certificates
        },
    },
    actions: {
        async getCertificates({ commit }) {
            const result = await apiGetCertificates.get()
            if (result) {
                commit('setCertificates', result)
            }
        },
    },
    getters: {
        certificates: (state) => state.certificates,
    },
}
