<template>
  <div class="row mb-3">
    <label for="inputText" class="col-form-label">{{ element.title }}</label>
    <label>{{ element.description }}</label>
    <div class="col-sm-8">
      <input 
        ref="inputRef" 
        @change="onValueChanged" 
        :placeholder="element.placeholder"
        class="form-control" />
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import useCurrencyInput from 'vue-currency-input'

import { evaluateFieldValue } from './../../services/fieldValueEvaluator'

export default {
  name: "CurrencyInput",
  props: [
    'id',
    'namespace',
    'json'
  ],
  setup(props) {
    const element = JSON.parse( props.json )
    
    const value = ref(null)

    const options = {
      locale: 'da-DK',
      currency: 'EUR',
      valueRange: undefined,
      precision: undefined,
      decimalDigitsReplacement: undefined,
      distractionFree: {
        hideNegligibleDecimalDigits: true,
        hideCurrencySymbol: true,
        hideGroupingSymbol: false
      },
      autoDecimalDigits: false,
      exportValueAsInteger: false,
      autoSign: true,
      useGrouping: true
    }

    const { formattedValue, inputRef, setOptions, setValue } = useCurrencyInput(options);

    return {
      value, 
      element,
      inputRef, 
      formattedValue,

      options,
      setValue,
      setOptions
    }
  },
  methods: {
    onValueChanged($event) {
      if ($event && 
          $event.detail &&
          $event.detail.number !== null && 
          this.value != $event.detail.number) {

        this.value = $event.detail.number

        this.$store.dispatch('forms/cacheField', { 
          path: this.namespace + '.' + this.id, 
          value: $event.detail.number 
        });
      }
    },

    refreshValue() {
      const context = this.$store.getters['forms/get'](this.namespace)
      if (!context) {
        return
      }

      // Update Value
      const loadedValue = this.$store.getters['forms/get'](this.namespace + '.' + this.id)
      this.value = loadedValue || ''
      this.setValue(this.value)

      // Update Currency Code
      const currency = evaluateFieldValue(this.element.currency, context) ?? 'EUR'
      if (this.options.currency !== currency) {
        this.options.currency = currency
        this.setOptions(this.options)
      }
    }
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === 'forms/fieldCached') {
        this.refreshValue()
      }
    });
  },
  mounted() {
    this.refreshValue()
  },
  beforeUnmount() {
    this.unsubscribe();
  },
};
</script>

<style lang="scss">
</style>
