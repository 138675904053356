import moment from 'moment';

const filters = {
    formatDate(value) {
        if (value) {
            return moment(String(value)).format('DD/MM-YYYY HH:mm')
        }
    },
    boolYesNo(value) {
        return value ? "Yes" : "No";
    }
}

export default filters;