import _ from "lodash";

export default {
    namespaced: true,
    state: () => ({
        loading: false,
        version: 0,
        forms: {},
    }),
    mutations: {
        gotForm(state, form) {
            state.forms[form.id] = form
        },

        formCached(state, payload) {
            // Ensure form
            if (!state.forms[payload.formId]) {
                state.forms[payload.formId] = {
                }
            }

            state.forms[payload.formId] = payload.data
        },

        fieldCached(state, payload) {
            _.set(state.forms, payload.path, payload.value)

            state.version += 1
        },

        fieldsReset(state, payload) {
            payload.nodes.forEach(node => {
                _.set(state.forms, node.path, undefined)

                const buttonPath = node.path + '_button'
                if (_.get(state.forms, buttonPath)) {
                    //console.log('RESETTING BUTTON:', buttonPath)
                    _.set(state.forms, buttonPath, undefined)
                }

                const statePath = node.path + '_state'
                if (_.get(state.forms, statePath)) {
                    //console.log('RESETTING STATE:', statePath)
                    _.set(state.forms, statePath, undefined)
                }
            })

            state.version += 1
        },

        isLoading(state, loading) {
            state.loading = loading
        }
    },
    actions: {
        async cacheForm({ commit }, { formId, data }) {
            commit('isLoading', true)

            const payload = {
                formId,
                data
            };

            commit('formCached', payload)
            
            commit('isLoading', false)
        },

        async cacheField({ commit }, { path, value }) {
            commit('isLoading', true)

            let storedValue = value
            
            let valueType = typeof value
            if (valueType !== 'string' && valueType !== 'number') {
                storedValue = JSON.stringify(value)
            }

            const payload = {
                path,
                value: storedValue
            };

            commit('fieldCached', payload)
            
            commit('isLoading', false)
        },

        async resetFields({ commit }, { nodes }) {
            commit('isLoading', true)

            const payload = {
                nodes
            };

            commit('fieldsReset', payload)
            
            commit('isLoading', false)
        }
    },
    getters: {
        form: (state) => (formId) => {
            return state.forms[formId]
        },

        get: (state) => (path) => {
            return _.get(state.forms, path);
        },

        version: (state) => {
            return state.version
        }
    }
};