import apiClient from '@/services/axios'

export async function get(courseId, lessonInstanceId) {
  console.log("IN GET LESSON INSTANCE");
  var response = await apiClient.get('/CourseInstances/' + courseId + '/' + lessonInstanceId)
  if (response) {
    return response.data
  }

  throw new Error('Unable to get Lesson Instance.')
}
