export default function evaluateDefaultSequence(sequence, sourceNode, context) {
    var condition = new Function('context', 'node', 'return context[node.id] != null');

    try {
        return condition(context, sourceNode)
    }
    catch {
        console.log('Warning: Could not evaluate DefaultSequence condition')

        return false
    }
}