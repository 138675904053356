import * as apiGetUserAccounts from '@/api/accounts/getUserAccounts'
import * as apiGetActiveUserProfile from '@/api/me/getActiveUserProfile'

export default {
    namespaced: true,
    state: () => ({
        loading: false,
        userAccounts: [],
        activeUserAccount: null,
        user: null
    }),
    mutations: {
        gotUserAccounts(state, accounts) {
            state.userAccounts = accounts

            if (!localStorage.activeUserAccount && accounts.length > 0) {
                localStorage.activeUserAccount = accounts[0].accountId
            }
        },

        isLoading(state, loading) {
            state.loading = loading
        }
    },
    actions: {
        async getUser({ commit, state }) {
            commit('isLoading', true)

            console.log('Getting User ');

            const result = await apiGetActiveUserProfile.get();

            if (result) {
                state.user = result
            }

            commit('isLoading', false)
        },
        async getUserAccounts({ commit }) {
            commit('isLoading', true)

            console.log('Getting User Accounts');

            const result = await apiGetUserAccounts.get();

            if (result) {
                commit('gotUserAccounts', result)
            }

            commit('isLoading', false)
        },

        async ensureUserAccounts({ commit, getters }) {
            commit('isLoading', true)

            if (getters.userAccounts.length === 0) {
                //await dispatch('getUserAccounts')
                commit('gotUserAccounts', [{ accountId: 'deb1e1f0-5754-4dcd-9e44-0934ab88001d', title: 'REKOM' }])
            }

            commit('isLoading', false)
        },

        async setActiveUserAccount({ commit, dispatch }, { accountId }) {
            commit('isLoading', true)

            localStorage.activeUserAccount = accountId

            await dispatch('getUserAccounts')

            commit('isLoading', false)
        }
    },
    getters: {
        user: (state) => state.user,
        userAccounts: (state) => state.userAccounts,
        activeUserAccount: (state) => {
            let activeAccount = state.userAccounts.find(userAccount => userAccount.accountId === localStorage.activeUserAccount)

            if (!activeAccount) {
                if (state.userAccounts.length === 0) {
                    localStorage.activeUserAccount = null
                    return null
                }

                localStorage.activeUserAccount = state.userAccounts[0].accountId
                activeAccount = state.userAccounts.find(userAccount => userAccount.accountId === localStorage.activeUserAccount)
            }

            return activeAccount
        }
    }
};