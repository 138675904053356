<template>
  <div v-if="mandatoryCourses">
    <div class="row" v-if="!course">
      <div
        class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4"
        v-for="item in mandatoryCourses"
        :key="item.id"
      >
        <CourseCard :course="item" @action="onClickCourse(item)" />
      </div>
    </div>

    <div class="row" v-else>
      <div
        class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4"
        v-for="item in course.children"
        :key="item.id"
      >
        <lesson-card
          :title="item.title"
          :status="item.status"
          :cooldownExpireDate="item.cooldownExpireDate"
          :lessonInstanceId="item.lessonInstanceId"
          :description="item.description"
          :imageUrl="item.imageUrl"
          @action="enroll(item)"
          actionText="Start"
          icon="fa-play-circle"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CourseCard from "../../common/CourseCard.vue";
import LessonCard from "../../common/LessonCard.vue";

export default {
  components: {
    CourseCard,
    LessonCard,
  },
  name: "MandatoryCoursesView",
  props: {
    mandatoryCourses: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      course: null,
    };
  },
  methods: {
    openFolder(item) {
      this.$emit("open-folder", item);
    },
    async onClickCourse(course) {
      console.log("COURSE", course);
      this.$router.push({
        name: "course",
        params: { courseId: course.id },
      });
    },
  },
};
</script>
