import axios from 'axios'

import { authPlugin as $auth } from '@/auth'

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL
})

apiClient.interceptors.request.use(request => {

  return new Promise(function (resolve) {

    $auth.getToken().then((accessToken) => {
      let token = accessToken
      if (token) {
        request.headers.Authorization = `Bearer ${token}`
        request.headers.AccessToken = token
      }

      const activeUserAccount = localStorage.activeUserAccount
      if (activeUserAccount) {
        request.headers.AccountId = activeUserAccount
      }

      resolve(request);
    });
  });
})

apiClient.interceptors.response.use(undefined, error => {

  console.log(error);

  // Errors handling
  const { response } = error
  const { data } = response
  if (data) {
      console.log('Warning - API:', data);
    /*notification.warning({
      message: data,
    })*/
  }
})

export default apiClient
