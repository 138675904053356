import mitt from 'mitt'

export var ServiceBus = (function(){
    function ServiceBus() {
        //do stuff
    }
    
    var _instance;
    var _bus;

    return {
        instance: function() {
            if (_instance == null) {
                _instance = new ServiceBus();
                // Hide the constructor so the returned object can't be new'd...
                _instance.constructor = null;

                _bus = mitt()
            }

            return _bus;
        }
   };
})();