<template>
  <div>
    <leap-form
      v-if="compiledSchema" 
      title='Test'
      :id="id"
      :session-id="sessionId"
      :namespace="namespace + '.' + id"
      :elements="compiledSchema">
    </leap-form>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'

export default {
  name: "FormInstance",
  props: [
    'id',
    'sessionId',
    'namespace',
    'json',
    'outgoing'
  ],
  setup(props) {
    const element = JSON.parse( props.json )

    const loading = ref(true)
    const compiledSchema = ref(undefined)

    const store = useStore()

    async function loadRequiredData() {
      loading.value = true

      await store.dispatch('formTemplates/ensureFormByIdentifier', { formIdentifier: element.identifier });

      const loadedForm = store.getters['formTemplates/form'](element.identifier)

      compiledSchema.value = loadedForm.compiledForm.nodes

      loading.value = false
    }

    loadRequiredData();

    onMounted(() => {
    })

    return {
      loading,
      element,
      compiledSchema
    }
  }
};
</script>

<style lang="scss">
</style>
