import apiClient from '@/services/axios'

export async function post({ lessonId, courseId }) {
  var response = await apiClient.post(`/CourseInstances/${courseId}/enrollLesson/${lessonId}`)
  if (response) {
    return response.data
  }

  throw new Error('Unable to start.')
}
