import * as apiGetCatalogue from '@/api/campusCatalogue/getCatalogue'
import * as apiResetCourse from '@/api/campusCatalogue/resetCourse'

export default {
    namespaced: true,
    state: () => ({
        loading: false,
        catalogue: [],
    }),
    mutations: {
        setCatalogue(state, catalogue) {
            state.catalogue = []
            state.catalogue = catalogue
        },
    },
    actions: {
        async getCatalogue({ commit }) {
            const result = await apiGetCatalogue.get()
            if (result) {
                commit('setCatalogue', result)
            }
        },
        async resetCourse({ commit }, courseId) {
            commit
            console.log('Resetting course:', courseId);
            await apiResetCourse.post(courseId)
        }
    },
    getters: {
        catalogue: (state) => state.catalogue,
    },
}