import apiClient from '@/services/axios'

export async function get(courseId, lessonInstanceId) {
  var response = await apiClient.get('/courseInstances/' + courseId + "/" + lessonInstanceId + '/passFailStats')
  if (response) {
    return response.data
  }

  throw new Error('Unable to get Lesson Instance stats.')
}
