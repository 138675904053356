<template>
  <div class="page">
    <div
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
      "
    >
      <div class="d-flex justify-content-center">
        <div
          class="spinner-grow"
          style="width: 5rem; height: 5rem"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingLessonPage",
  components: {},
  props: ["lessonId", "courseId"],
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    lessonInstance() {
      return this.$store.getters["lessons/lessonInstance"](this.lessonId);
    },
  },
  methods: {
    async loadRequiredData() {
      this.loading = true;

      console.log("LOADING WIZARD:", this.lessonId, this.coursseId);
      await this.$store.dispatch("lessons/getLessonInstance", {
        courseId: this.$route.params.courseId,
        lessonInstanceId: this.lessonId,
      });

      console.log(
        "Loaded lesson instance with ID:",
        this.lessonId,
        this.lessonInstance
      );

      const firstLessonCategory =
        this.lessonInstance.lesson.compiledLesson.categories[0];
      const firstPageSlug = firstLessonCategory.pages[0].slug;

      console.log("First page slug:", firstPageSlug);
      console.log("In params bug", this.$route.params.courseId);
      const courseId = this.$route.params.courseId;
      console.log("Course ID:", courseId);

      this.$router.push({
        name: "preview-lesson-page",
        params: {
          lessonId: this.lessonId,
          pageKey: firstPageSlug,
          courseId: courseId,
        },
      });

      this.loading = false;
    },
  },
  created() {
    this.loadRequiredData();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
