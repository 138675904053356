<template>
  <div class="vs-card-content type-1">
    <div class="vs-card form-card">
      <div class="vs-card__img"></div>
      <div class="vs-card__text form-text">
        <div>
          <!--<div class="vs-card__title">
            <h3>FORM TITLE</h3>
          </div>-->
          <leap-form
            :id="id"
            :lessonInstanceId="props.lessonInstanceId"
            :session-id="id"
            :namespace="id"
            :isPassFail="isPassFail"
            :title="'Test Form'"
            :elements="elements"
          >
          </leap-form>
        </div>

        <div class="mt-4 mb-2 left-right-align">
          <button
            v-if="showPreviousButton"
            type="submit"
            class="btn btn-success px-5"
          >
            Forrige
          </button>
          <button v-if="showEndButton" type="submit" class="btn btn-info px-5">
            Afslut
          </button>
          <button
            v-if="showNextButton"
            type="submit"
            class="btn btn-success px-5"
          >
            Næste
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

import { ServiceBus } from "@/leapform/LeapFormServiceBus";

import LeapForm from "@/leapform/LeapForm";

export default {
  name: "Form",
  components: {
    LeapForm,
  },
  props: ["id", "lessonInstanceId", "elements", "isPassFail"],
  setup(props) {
    const form = ref(null);

    const showPreviousButton = ref(false);
    const showNextButton = ref(false);
    const showEndButton = ref(false);

    //console.log('IDENTIFIER - Form ID:', props.id)

    return {
      form,
      props,
      showPreviousButton,
      showNextButton,
      showEndButton,
    };
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation /*, state*/) => {
      if (mutation.type === "forms/fieldCached") {
        //console.log(`Updating to ${state.forms.version}`);

        ServiceBus.instance().emit("form:" + this.id, {
          type: "field-updated",
        });
      }
    });
  },
  beforeUnmount() {
    this.unsubscribe();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.form-card label.col-form-label {
  font-weight: 500;
  font-size: 20px;
}
</style>
<style scoped>
.vs-card {
  box-shadow: 0 1px 2.2px rgba(0, 0, 0, 0.02),
    0 2.3px 5.3px rgba(0, 0, 0, 0.028), 0 4.4px 10px rgba(0, 0, 0, 0.035),
    0 7.8px 17.9px rgba(0, 0, 0, 0.042), 0 14.6px 33.4px rgba(0, 0, 0, 0.05),
    0 35px 80px rgba(0, 0, 0, 0.07);
}
.form-card {
  max-width: none;
  width: 100%;

  display: flex;
  flex-direction: column;

  min-height: 347px;
}

.form-text {
  padding: 10px 25px 15px 25px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.left-right-align {
  display: flex;
  justify-content: space-between;
}
</style>
