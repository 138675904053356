<template>
  <div>
    <!-- Button to open modal -->
    <a id="feedback-button" href="#" @click="showModal = true">Feedback</a>

    <!-- Modal -->
    <Dialog v-model:visible="showModal" modal header="Feedback" class="feedback-modal">
      <div class="p-fluid">
        <div class="p-field">
          <label for="course">Subject</label>
          <InputText id="course" v-model="subject" />
        </div>
        <div class="p-field">
          <label for="comment">Feedback</label>
          <Textarea id="comment" v-model="feedback" :rows="5" />
        </div>
      </div>
      <template #footer>
        <div class="flex">
          <Button label="Submit" class="grow" @click="submitFeedback" />
        </div>
      </template>
    </Dialog>
  </div>
</template>
<script>
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";
import Button from "primevue/button";
import { useToast } from "vue-toastification";

export default {
  components: {
    Dialog,
    InputText,
    Textarea,
    Button,
  },
  data() {
    return {
      showModal: false,
      subject: "",
      feedback: "",
    };
  },
  methods: {
    async submitFeedback() {

      const toast = useToast();
      const dto = {
        "subject": this.subject,
        "description": this.feedback
      }

      try {
        await this.$store.dispatch("feedback/submitFeedback", dto);
        this.subject = "";
        this.feedback = "";
        this.showModal = false;
        toast.success("Feedback submittted. Thank you!");
      } catch (err) {
        toast.error("Error submitting feedback: " + err);
      }

      // Clear form fields


      // Close modal

    },
  },
};
</script>
<style scoped>
#feedback-button {
  position: fixed;
  top: 50%;
  right: 10px;
  transform: translateY(-50%) rotate(-90deg);
  transform-origin: 100% 50%;
  background-color: black;
  color: white;
  padding: 10px 20px;
  margin-right: 15px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  border: none;
  outline: none;
  cursor: pointer;
  white-space: nowrap;
}

.loading-container {
  padding-top: 80px;
}
</style>