<template>
  <div>
    <overlay
      animate="false"
      :opened="opened"
      :visible="visible"
      closeable="false"
      @closed="(event) => onClose(event, 'closed')"
    >
      <div>
        <h5>{{ element.title }}</h5>
        <div class="mb-3" v-html="text"></div>
      </div>

      <div v-if="options.length > 0">
        <button
          v-for="(option, index) in options"
          :key="index"
          @click="onButtonClick(option.data.value)"
          type="button"
          class="mr-2 btn btn-info"
        >
          {{ option.data.title }}
        </button>
      </div>
    </overlay>
  </div>
</template>

<script>
import { ServiceBus } from "@/leapform/LeapFormServiceBus";

import { ref, onMounted } from "vue";
import { useStore } from "vuex";

import Overlay from "@/components/Overlay";

export default {
  name: "PopUp",
  components: {
    Overlay,
  },
  props: ["id", "namespace", "json", "outgoing"],
  setup(props) {
    const options = ref([]);
    const element = ref({});
    const selectedValue = ref("");
    const opened = ref(false);
    const visible = ref(false);
    const didClick = ref(false);
    const text = ref("");

    const urlRegex = /\[(.*?)\]\((.*?)\)/g; // Markdown URL syntax (text)[url]

    const store = useStore();

    element.value = JSON.parse(props.json);
    text.value = element.value.content.replace(
      urlRegex,
      '<a href="$2" target="_blank">$1</a>'
    );

    const buttonOptions = props.outgoing.filter(
      (x) => x.type === "ButtonOptionSequence"
    );
    options.value = buttonOptions.map((x) => ({
      id: x.id,
      targetRef: x.targetRef,
      data: JSON.parse(x.json),
    }));
    options.value = options.value.sort((a, b) => a.data.order - b.data.order);

    const refreshValue = () => {
      const loadedValue = store.getters["forms/get"](
        props.namespace + "." + props.id
      );

      selectedValue.value = loadedValue || "";
    };

    const onActivate = () => {
      refreshValue();

      //console.log('SELECTED VALUE:', selectedValue.value)

      if (selectedValue.value !== "") {
        return;
      }

      opened.value = true;
      visible.value = true;
    };

    const onClose = (event, value) => {
      //console.log('ON CLOSE:', event)

      if (event !== "button") {
        if (didClick.value === true && event === "visible") {
          store.dispatch("forms/cacheField", {
            path: props.namespace + "." + props.id + "_button",
            value: selectedValue.value,
          });
        }

        return;
      }

      didClick.value = true;

      opened.value = false;
      visible.value = false;

      selectedValue.value = value;

      //console.log('Closing popup:', event, ' - ', props.id, ' - ', value)
      /*
      store.dispatch('forms/cacheField', { 
        path: props.namespace + '.' + props.id, 
        value: selectedValue.value 
      });*/
    };

    const onButtonClick = (value) => {
      //console.log('Button click popup:', props.id, ' - ', value)

      onClose("button", value);
    };

    ServiceBus.instance().on("node:" + props.id, (event) => {
      if (event.type === "activated") {
        onActivate();
      }
    });

    onMounted(() => {
      //console.log('Popup: onMounted')
      onActivate();
    });

    return {
      element,
      options,
      selectedValue,
      text,
      refreshValue,
      onClose,
      onButtonClick,

      opened,
      visible,
    };
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === "forms/fieldCached") {
        this.refreshValue();
      }
    });
  },
  mounted() {
    this.refreshValue();
  },
  beforeUnmount() {
    this.unsubscribe();
  },
};
</script>

<style lang="scss">
</style>
