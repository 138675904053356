import { createStore } from "vuex"

import accounts from './modules/accounts'

import forms from './modules/forms'

import lessons from './modules/lessons'
import lessonTemplates from './modules/lessonTemplates'

import courses from './modules/courses'

import certificates from "./modules/certificates"
import feedback from "./modules/feedback"
import campusCatalogue from "./modules/campusCatalogue"

const store = createStore({
   state: {
      name: "CampusOnline"
   },
   modules: {
      feedback,
      accounts,

      forms,

      courses,

      lessons,
      lessonTemplates,
      certificates,
      campusCatalogue
   }
})

export default store