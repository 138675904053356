<template>
  <div style="">
    <label class="col-form-label">{{ title }}</label>
    <p>{{ description }}</p>

    <div class="flow-root px-3 mt-4">
      <ul role="list" class="-mb-8">
        <li v-for="(event, eventIdx) in filesToTimeline" :key="event.id">
          <div class="relative pb-8">
            <span
              v-if="eventIdx !== filesToTimeline.length - 1"
              class="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200"
              aria-hidden="true"
            />
            <div class="relative flex space-x-3">
              <div>
                <span :class="[event.iconBackground, 'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white']">
                  <component :is="event.icon" class="h-5 w-5 text-white" aria-hidden="true" />
                </span>
              </div>
              <div class="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                <div>
                  <p class="text-sm text-gray-500">
                    {{ event.content }}
                    <a :href="event.href" target="_blank" class="font-medium text-gray-900">{{ event.target }}</a>
                  </p>
                </div>
                <div class="whitespace-nowrap text-right text-sm text-gray-500">
                  <button
                    type="button"
                    @click="downloadUrl(event.href)"
                    class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800"
                  >
                    Download
                  </button>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />
    <button @click="onclick" type="button" class="btn btn-md btn-success" style="padding-left: 50px; padding-right: 50px">
      {{ nextButtonTitle }}
    </button>
  </div>
</template>

<script>
import { DocumentArrowDownIcon } from "@heroicons/vue/20/solid";

export default {
  name: "MaterialDownload",
  components: { DocumentArrowDownIcon },
  props: ["id", "namespace", "json", "outgoing"],
  computed: {
    filesToTimeline() {
      return this.files.map(e => {
        return {
          id: 1,
          content: "Attachment",
          target: e.name,
          href: e.url,
          icon: DocumentArrowDownIcon,
          iconBackground: "bg-gray-500"
        };
      });
    }
  },
  data() {
    return {
      buttonClicked: false,
      files: [],
      title: "Download materials",
      description: "Please download and read the document(s) below, and then press 'Next'",
      nextButtonTitle: "Next"
    };
  },
  methods: {
    refreshValue() {
      const loadedButtonValue = this.$store.getters["forms/get"](this.namespace + "." + this.id + "_button");
      if (loadedButtonValue === "ok") {
        this.buttonClicked = true;
      } else {
        this.buttonClicked = false;
      }
    },
    onclick() {
      this.$store.dispatch("forms/cacheField", {
        path: this.namespace + "." + this.id + "_button",
        value: "ok"
      });

      this.buttonClicked = true;
    },
    downloadUrl(url) {
      console.log("downloadUrl", url);
      window.open(url, "_blank");
    }
  },
  created() {
    this.unsubscribe = this.$store.subscribe(mutation => {
      if (mutation.type === "forms/fieldCached") {
        this.refreshValue();
      }
    });
  },
  mounted() {
    this.refreshValue();
    const element = JSON.parse(this.json);
    this.title = element.title;
    this.description = element.description;
    this.nextButtonTitle = element.nextButtonTitle ?? "Next";
    const fileUrls = element.content.split(",");
    const fileNames = element.value.split(",");
    this.files = fileUrls.map((e, idx) => {
      return {
        name: fileUrls.length === fileNames.length ? fileNames[idx] : "No name specified",
        url: e
      };
    });
  },
  beforeUnmount() {
    this.unsubscribe();
  }
};
</script>

<style lang="scss">
</style>
