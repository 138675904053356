<template>
  <div class="page">
    <h2 class="page-title">Certificates</h2>
    <div>
      <div v-if="loading" class="loading-container mx-auto">
        <Loading text="Loading your certificates" />
      </div>

      <div class="mt-3" v-else>
        <div v-if="certificates.length < 1" class="flex flex-col items-center">
          <img
            src="@/assets/icons/empty-box.png"
            alt=""
            style="opacity: 0.3; width: 178px; height: 178px"
          />
          <h3 class="text-gray-400">You have no certificates yet</h3>
        </div>

        <div v-else>
          <div class="row" v-if="activeFolder == null">
            <div
              v-for="(item, index) in certificates"
              :key="index"
              class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4"
            >
              <div
                class="card position-relative h-100 pointer"
                style="cursor: pointer"
                @click="certificateClicked(item)"
              >
                <img
                  class="card-img-top-rounded"
                  :src="item.imageUrl"
                  alt="Image"
                />

                <div class="card-body">
                  <h5 class="card-title">{{ item.title }}</h5>
                  <p class="card-text">{{ item.description }}</p>
                  <p class="card-text text-center">
                    {{ moment(item.created).format("DD/MM/YYYY") }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
//import SelectPositionPopup from "./SelectPositionPopup.vue";

export default {
  data() {
    return {
      moment,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      certificates: "certificates/certificates",
    }),
  },
  methods: {
    async certificateClicked(certificate) {
      console.log("certificateClicked", certificate);
      window.open(certificate.pdfUrl, "_blank").focus();
    },
    async loadRequiredData() {
      this.loading = true;
      try {
        await this.$store.dispatch("certificates/getCertificates");
      } catch (err) {
        console.error("Error could not load certificates");
      }
      this.loading = false;
    },
  },
  async mounted() {
    await this.loadRequiredData();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card-img-top-rounded {
  width: 100%;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.button-upper-right {
  position: absolute;
  top: 24px;
  right: 24px;
}
.card {
  border-radius: 16px;
  border: none;
  box-shadow: 0 1px 2.2px rgb(0 0 0 / 2%), 0 2.3px 5.3px rgb(0 0 0 / 3%),
    0 4.4px 10px rgb(0 0 0 / 4%), 0 7.8px 17.9px rgb(0 0 0 / 4%),
    0 14.6px 33.4px rgb(0 0 0 / 5%), 0 35px 80px rgb(0 0 0 / 7%);
}

h2.page-title {
  font-weight: 800;
  color: #17312d;
}
h5.page-title {
  color: #17312d;
}

.card-img-overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card-title {
  overflow: hidden;
  text-overflow: ellipsis;
  color: #000;
}

.btn.round {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

h5.copy-to-clipboard:hover {
  cursor: pointer;
  color: cornflowerblue;
}

.row {
  display: flex;
}

.left {
  flex: 1;
}

.right {
  width: 350px;
}
</style>