import apiClient from '@/services/axios'

export async function post(courseId) {
  console.log('Enrolling course...')
  var response = await apiClient.post(`CourseInstances/${courseId}/enroll`)
  if (response) {
    return response.data
  }

  throw new Error('Unable to start.')
}
