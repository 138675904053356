export default function evaluateRadioListOptionSequence(sequence, sourceNode, context, isPassFail) {
    const sequenceData = JSON.parse(sequence.json)
    let condition = null;

    if (isPassFail) {
        condition = new Function('context', 'node', 'return context[node.id] !== undefined && context[node.id] !== null');
    } else {
        condition = new Function('context', 'node', 'return context[node.id] === \'' + sequenceData.value + '\'');
    }

    try {
        return condition(context, sourceNode)
    }
    catch {
        console.log('Warning: Could not evaluate RadioListOptionSequence condition')

        return false
    }
}