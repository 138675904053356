export default function evaluateButtonOptionSequence(sequence, sourceNode, context) {
    const sequenceData = JSON.parse(sequence.json)
    var condition = new Function('context', 'node', 'return context[node.id + \'_button\'] === \'' + sequenceData.value + '\'');
    //var condition = new Function('context', 'node', 'return context[node.id] === \'' + sequenceData.value + '\'');

    try {
        return condition(context, sourceNode)
    }
    catch {
        console.log('Warning: Could not evaluate ButtonOptionSequence condition')

        return false
    }
}