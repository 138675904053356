<template>
  <div>
    <h5 style="margin-top:20px;">{{element.title}}</h5>
    <div v-if="compiledSchema">
        <leap-form
            v-for="(formInstance, index) in formInstances" :key="index"
            title='Test'
            :id="id + '::' + index"
            :session-id="sessionId"
            :namespace="namespace + '.' + id + '::' + index"
            :elements="compiledSchema"
            v-model="formInstances[index]"
            style="background-color: #f9f9f9;padding: 12px;margin-top:10px;border-radius:5px;">
        </leap-form>
    </div>
    <div style="justify-content: center;display: flex;">
        <button @click="addNewFormInstance" type="button" class="btn btn-secondary mt-2">{{element.addButtonTitle}}</button>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'

export default {
  name: "FormRepeater",
  props: [
    'id',
    'sessionId',
    'namespace',
    'json',
    'outgoing'
  ],
  setup(props) {
    const element = JSON.parse( props.json )

    const loading = ref(true)
    const compiledSchema = ref(undefined)
    const formInstances = ref([])

    const store = useStore()

    async function loadRequiredData() {
      loading.value = true

      await store.dispatch('formTemplates/ensureFormByIdentifier', { formIdentifier: element.identifier });

      const loadedForm = store.getters['formTemplates/form'](element.identifier)

      compiledSchema.value = loadedForm.compiledForm.nodes

      loading.value = false
    }

    loadRequiredData();

    function addNewFormInstance() {
        formInstances.value.push({})
    }

    return {
      loading,
      element,
      compiledSchema,
      addNewFormInstance
    }
  }
};
</script>

<style lang="scss">
</style>
