import apiClient from '@/services/axios'

export async function get() {
  var response = await apiClient.get('/CampusCatalogue')
  if (response) {
    console.log('Got catalogue:', response.data);
    return response.data
  }

  throw new Error('Unable to get catalogue.')
}
