<template>
  <div class="page" style="display: flex;flex: 1;flex-direction: column;">
    <div style="display: flex;flex: 1;">
      <LeftMenuLayout>
        <template v-slot:content>
          <router-view :key="$route.fullPath"/>
        </template>
      </LeftMenuLayout>
    </div>
  </div>
</template>

<script>
export default {
  name: "LessonLayout",
  components: {
  //    LessonSidebar
  },
  props: ['lessonId']
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* .page {
  background-image: url('~@/assets/campus-bg.png')
} */
</style>