<template>
  <div>
    <form>
      <form-generator
        :id="id"
        :session-id="sessionId"
        :namespace="namespace"
        :lessonInstanceId="props.lessonInstanceId"
        :elements="elements"
        :isPassFail="isPassFail"
      >
      </form-generator>
    </form>
  </div>
</template>

<script>
import FormGenerator from "./components/FormGenerator";

export default {
  name: "LeapForm",
  components: {
    FormGenerator,
  },
  props: [
    "id",
    "sessionId",
    "namespace",
    "title",
    "elements",
    "lessonInstanceId",
    "isPassFail"
  ],
  setup(props) {
    return { props };
  },
};
</script>
