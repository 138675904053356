import { 
    setupAuth as implSetupAuth, 
    authPlugin as implAuthPlugin 
} from './adb2c'    // Change to actual implementation

import { watchEffect } from 'vue'

export const setupAuth = implSetupAuth
export const authPlugin = implAuthPlugin

export const routeGuard = (to, from, next) => {
    const { isAuthenticated, loading, loginWithRedirect } = authPlugin
  
    const verify = () => {
      // If the user is authenticated, continue with the route
      if (isAuthenticated.value) {
        return next()
      }
  
      // Otherwise, log in
      loginWithRedirect({ appState: { targetUrl: to.fullPath } })
    }
  
    // If loading has already finished, check our auth state using `fn()`
    if (!loading.value) {
      return verify()
    }
  
    // Watch for the loading property to change before we check isAuthenticated
    watchEffect(() => {
      if (loading.value === false) {
        return verify()
      }
    })
  }